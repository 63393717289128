import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _18d2139a = () => interopDefault(import('../pages/bereikbaarheid/index.vue' /* webpackChunkName: "pages/bereikbaarheid/index" */))
const _fba9bb34 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _0e87255c = () => interopDefault(import('../pages/vrijwilligers/index.vue' /* webpackChunkName: "pages/vrijwilligers/index" */))
const _51ad801a = () => interopDefault(import('../pages/general/_id.vue' /* webpackChunkName: "pages/general/_id" */))
const _4a6af364 = () => interopDefault(import('../pages/news/_id.vue' /* webpackChunkName: "pages/news/_id" */))
const _f1ce498a = () => interopDefault(import('../pages/performance/_id.vue' /* webpackChunkName: "pages/performance/_id" */))
const _7d0d6458 = () => interopDefault(import('../pages/theme/_id.vue' /* webpackChunkName: "pages/theme/_id" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/bereikbaarheid",
    component: _18d2139a,
    name: "bereikbaarheid"
  }, {
    path: "/news",
    component: _fba9bb34,
    name: "news"
  }, {
    path: "/vrijwilligers",
    component: _0e87255c,
    name: "vrijwilligers"
  }, {
    path: "/general/:id?",
    component: _51ad801a,
    name: "general-id"
  }, {
    path: "/news/:id",
    component: _4a6af364,
    name: "news-id"
  }, {
    path: "/performance/:id?",
    component: _f1ce498a,
    name: "performance-id"
  }, {
    path: "/theme/:id?",
    component: _7d0d6458,
    name: "theme-id"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
