<template>
  <div class="gb-field gb-field__input--text"
       :class="{active: isActive, warning: inputing, error: isError, 'gb-animate-shake': isShake && isShowCharacterCounter}">
    <label class="gb-field__label">{{ label }}</label>
    <input class="gb-field__area"
           type="text"
           autocomplete="off"
           :value="value"
           @input="input"
           @focus="focus"
           @blur="blur">
    <span class="gb-character-counter"
          v-if="isShowCharacterCounter">{{ characterCounterInfo }}</span>
    <p class="gb-field__invalid" v-if="isError">{{ (error && error.message) || errorMessage }}</p>
  </div>
</template>

<script>
import InputBase from './input'

export default {
  name: 'app-text-select',
  mixins: [InputBase],
  computed: {
    isShake() {
      if (!this.showError || !this.isValidValue) {
        return false
      }
      if (this.value.length > this.maxLength) {
        return true
      }
      return false
    }
  },
  methods: {
    focus(e) {
      this.active = true
      this.inputing = true
      this.showCharacterCounter = true
      this.selectAll(e)
      this.$emit('focus', e)
    },
    input(e) {
      this.showError = true
      this.$emit('input', e.target.value)
    }
  }
}
</script>

