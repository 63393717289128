import gql from 'graphql-tag'

export const listAll = {
  query: gql`{
      queryContentFooterContents(top:0, orderby: "data/SortOrder/iv") {
        data {
          category
          {
              iv
          },
          title
          {
              iv
          },
          shortName
          {
              iv
          },
          mainMenu{
              iv
          }
        }
      }
    }`
}

export const findByName = {
  query: gql`query GetMenuByCategory($filter: String!) {
    queryContentFooterContents(top: 1, filter: $filter) {
      data {
        category {
          iv
        },
        title {
          iv
        },
        body {
          iv
        },
        containsTwoColumns {
          iv
        },
        secondColumn {
          iv
        },
        introduction {
          iv
        }
      }
    }
  }`,
    variables(shortName) {
      let filter = ''
      if (shortName && shortName.length > 0) {
        let normalizedShortName = shortName ? shortName.toString().replace(/'/g, '\'\'') : ''
        filter = `data/ShortName/iv eq '${normalizedShortName}'`
      }
      return {
        filter
      }
    }
}

export const listInMenu = {
  query: gql`{
          queryContentFooterContents(top:0, filter: "data/MainMenu/iv eq true", orderby: "data/SortOrder/iv") {
            data {
              title {
                  iv
              },
              shortName {
                  iv
              }
            }
          }
        }`
}
