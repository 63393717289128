<template>
  <div
    :style="{...containerStyle, backgroundColor: selected ? '#D7EFED' : 'transparent'}"
    :class="{open: show , dropup: false }"
  >
    <p
      class="gb-field__select-holder"
      role="button"
      aria-haspopup="true"
      aria-expanded="show"
      @click.prevent="toggle($event)"
    >
      {{ displayItem }}
    </p>
    <button
      v-if="selected"
      @click="select({ value: '' }, $event)"
      class="gb-field__select-close"
    >
      X
    </button>
    <div
      class="gb-field__dropdown-menu"
      :style="style"
      :class="{open: show}"
    >
      <div class="gb-field__dropdown-menu-wrapper">
        <ul
          :style="style"
          :class="{'right' : position == 'right'}"
          aria-labelledby="dLabel"
        >
          <li
            v-for="item in list"
            :key="item.key"
            class="uppercased"
            :class="{'disabled':item.disabled ,'selected':validSelected(item)}"
          >
            <a
              @click.stop.prevent="select(item, $event)"
              :class="item.class"
            >
              <span class="gb-field__col--two">{{ item.text }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <select
      style="display:none;"
      :id="id"
      :name="name"
      :value="item"
    >
      <option
        v-for="option in list"
        :key="option.key"
        :value="option.key"
      >
        {{ option.text }}
      </option>
    </select>
  </div>
</template>

<script>
  import _ from 'lodash'
  import { attachEvent } from '@/libs/utils/event-helper'
  import { keycodeMap } from './keycode-map'

  const separator = '---------------------------------'

  export default {
    inheritAttrs: false,

    data() {
      return {
        show: false,
        style: {},
        containerStyle: {},
        dropup: false,
        focusItem: null,
        focusIndex: 0
      }
    },
    model: {
      prop: 'value ',
      event: 'change'
    },
    computed: {
      selected() {
        return !!(this.value && !_.isEmpty(this.value))
      },

      displayItem() {
        // If zero show default message
        if (
          (this.returnObject && this.item && !this.item.text) ||
          (!this.returnObject && this.item && this.item.length === 0) ||
          this.forceDefault
        ) {
          return this.defaultText
        }

        let displayText = ''
        // Show selected item
        if (this.returnObject && this.item) {
          displayText = this.item.displayText
            ? this.item.displayText
            : this.item.text
        }
        if (displayText) {
          return displayText
        }

        // Show text that coresponds to the item key
        if (!this.returnObject && this.item) {
          let result = this.item || ''
          this.list.forEach(item => {
            if (item.key !== undefined && this.item !== undefined) {
              if (item.key.toString() === this.item.toString()) {
                result = item.displayText ? item.displayText : item.text
              }
            }
          })
          return result
        }

        return this.list.length > 0 ? this.list[0].text : ''
      },
      list() {
        return this.items.map(option => {
          if (option.text === separator) {
            option.disabled = true
            option.class = 'gb-field__select-option-line'
          }
          return option
        })
      },
      item() {
        if (
          this.list.length > 0 &&
          this.value &&
          !_.find(this.list, { key: this.value })
        ) {
          return ''
        }
        return this.value
      }
    },
    props: {
      id: {
        type: String
      },
      name: {
        type: String
      },
      value: {
        type: [String, Number],
        required: false
      },
      items: {
        type: Array,
        default: function() {
          return []
        },
        required: true
      },
      dataSize: {
        type: Number,
        default: 6
      },
      dropupAuto: {
        type: Boolean,
        default: true
      },
      position: {
        type: String,
        default: 'left'
      },
      defaultText: {
        type: String,
        default: 'Plase select one'
      },
      forceDefault: {
        type: Boolean,
        default: false
      },
      returnObject: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      toggle(e) {
        if (this.disabled) return
        this.show = !this.show
      },
      select(item, e) {
        if (item.disabled) {
          e.preventDefault()
        } else {
          let newValue = this.returnObject ? item : item.key
          if (newValue !== this.value) {
            this.$emit('change', newValue, this.value)
            this.$nextTick(() => {
              let element = this.$el.querySelector('select')
              if ('createEvent' in document) {
                let evt = document.createEvent('HTMLEvents')
                evt.initEvent('change', true, true)
                element.dispatchEvent(evt)
              } else {
                element.fireEvent('onchange')
              }
            })
          }
          this.show = false
        }
      },
      validSelected(item) {
        let key = this.returnObject ? this.item.key : this.item
        let focusKey = this.focusItem ? this.focusItem.key : this.focusItem
        return item.key === key || item.key === focusKey
      },
      keyDownSearchSelectIndex(inputChar, options, option) {
        let validItems = _.filter(options, function(o) {
          return o.text.toLowerCase().indexOf(inputChar) === 0
        })
        if (validItems.length < 1) {
          return
        }
        let lastItemText = validItems[validItems.length - 1].text
        let regex = new RegExp('^' + inputChar, 'i')
        let index = _.findIndex(options, it => {
          if (option && regex.test(option.text)) {
            return it.text === option.text
          }
          return regex.test(it.text)
        })
        if (index === -1) {
          return
        }
        // find second case options exist the same option
        if (
          option &&
          index !== option.index &&
          option.text.toLowerCase().indexOf(inputChar) === 0
        ) {
          index = _.findLastIndex(options, function(it) {
            return it.text === option.text
          })
        }
        // find next option
        let targetItem = options[index]
        if (option && targetItem.text === option.text) {
          index = index + 1
        }
        // return first option
        if (
          lastItemText === targetItem.text &&
          option &&
          targetItem.text === option.text
        ) {
          index = _.findIndex(options, it => {
            return regex.test(it.text)
          })
        }
        // find LastIndex option case options exist the same option
        let nextItem = options[index]
        if (nextItem.text.toLowerCase().indexOf(inputChar) !== 0 && option) {
          let validItemIndex = _.findIndex(validItems, ot => {
            return ot.text === option.text
          })
          let nextvalidItem = validItems[validItemIndex + 1]
          index = _.findLastIndex(options, function(o) {
            return o.text === nextvalidItem.text
          })
        }
        return index
      }
    },
    mounted() {
      this.$nextTick(() => {
        document.addEventListener('click', e => {
          if (!this.$el.contains(e.target) && this.show) {
            this.focusIndex = 0
            if (this.focusItem) {
              this.focusItem.disabled = false
              this.select(this.focusItem, null)
            }
            this.focusItem = null
            this.show = false
          }
        })
        this.$set(
          this.containerStyle,
          'width',
          this.dataWidth && this.dataWidth !== 'fit' ? this.dataWidth : null
        )

        // TODO: SGP-11233 m6:When user selects country, user can not select countries quickly by inputting the first letter of countries.It is different with official website.
        attachEvent(this.$el, 'keydown', e => {
          let inputChar = keycodeMap[e.keyCode]
          if (!inputChar) {
            return
          }
          let index = this.keyDownSearchSelectIndex(
            inputChar,
            this.items,
            this.focusItem
          )
          if (!index) {
            index = this.focusIndex
          }
          let targetItem = this.items[index]
          this.focusItem = targetItem
          this.focusItem.index = index
          let container = this.$el.querySelector('.field__dropdown__menu ul')
          let containerTop = container.getBoundingClientRect().bottom
          let target = container.querySelectorAll('li a')[index]
          if (target) {
            target.focus()
            let pos = target.getBoundingClientRect()
            let containerHeight = container.clientHeight
            container.scrollTop += pos.bottom - containerTop + containerHeight / 2
          }
        })
      })
    }
  }
</script>
