import Vuex from 'vuex'

const botIPs = process.env.BOT_IPS.split(',')

const createStore = () => {
  const store = new Vuex.Store({
    actions: {
      async nuxtServerInit ({ commit, state }, { res, req, redirect }) {
        if (
          botIPs.indexOf(req.connection.remoteAddress) > -1 ||
          req.headers['user-agent'] === 'AlwaysOn'
        ) {
          console.log('from azure bot, redirect to https://www.reuring.com')
          redirect(`https://${process.env.CUSTOM_DOMAIN}`)
          return
        }

        const host = req.headers.host.toLowerCase()

        if (!process.env.SERVER_LOGGING_DISABLED) {
          console.log(`
============ REQ START ============
host: ${host}
url: ${req.url}
cache-control: ${req.headers['cache-control']}
cookie: ${req.headers['cookie']}
user-agent: ${req.headers['user-agent']}
ip: ${req.headers['x-forwarded-for'] || req.connection.remoteAddress}
============ REQ END ============`
          )
        }
        if (process.env.NODE_ENV === 'production'
          && process.env.isProd
          && host !== process.env.CUSTOM_DOMAIN.toLowerCase()) {
          redirect(`https://${process.env.CUSTOM_DOMAIN}`)
          return
        }
      }
    },

    strict: process.env.NODE_ENV !== 'production'
  })

  return store
}

export default createStore
