<template>
  <footer>
    <div class="footer-background">
      <div class="footer flex-box">
        <div class="col-logo">
          <h1 class="logo">
            <a
              href="/"
              class="logo-inner"
            >
              <span class="logo-brand"><img
                  src="~assets/images/Reuring_logo.svg"
                  alt="logo"
                ></span>
              <span class="logo-name">festival</span>
            </a>
          </h1>
          <div class="festival"><span class="time">2024</span></div>
        </div>
        <div
          class="col-about"
          v-for="(menuItem,key) in menuList"
          :key="key"
        >
          <ul class="list">
            <li>
              <h6 class="title uppercased">{{ key }}</h6>
            </li>
            <li
              v-for="(value,key) in menuItem"
              :key="key"
            >
              <nuxt-link
                :to="value.routerTo"
                class="anchor-item"
              >{{ value.name }}</nuxt-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-social social-bar">
      <a
        href="https://www.facebook.com/Reuring"
        target="_blank"
        class="social-item"
      >
        <i class="fa fa-facebook"></i>
      </a>
      <a
        href="https://www.instagram.com/reuringfestival/"
        target="_blank"
        class="social-item"
      >
        <i class="fa fa-instagram"></i>
      </a>
      <a
        href="https://open.spotify.com/user/reuringfestival?si=bWVhrY8MT_ywo3smGfR7Og"
        target="_blank"
        class="social-item"
      >
        <i class="fa fa-spotify"></i>
      </a>
      <a
        href="https://www.youtube.com/user/reuringfestival"
        target="_blank"
        class="social-item"
      >
        <i class="icon icon-youtube"></i>
      </a>
      <a
        href="https://www.flickr.com/photos/reuringfestival"
        target="_blank"
        class="social-item"
      >
        <i class="icon icon-flickr"></i>
      </a>
    </div>
  </footer>
</template>
  <script>
  import _ from 'lodash'
  import { listAll as listAllContent } from '@/libs/apollo/queries/content-footer'

  export default {
    name: 'app-footer',
    data () {
      return {
        menuList: []
      }
    },
    apollo: {
      menuList: {
        query: listAllContent.query,
        prefetch: true,
        fetchPolicy: 'cache-first',
        update (data) {
          let menuList = _.map(data.queryContentFooterContents, it => {
            return {
              category: it.data.category.iv,
              name: it.data.title.iv,
              routerTo: {
                name: 'general-id',
                params: {
                  id: it.data.shortName.iv
                }
              }
            }
          })

          let groups = _.groupBy(menuList, i => i.category.toUpperCase())
          return {
            PROGRAMMA: groups['PROGRAMMA'],
            'STEUN REURING': groups['STEUN REURING'],
            'OVER REURING': groups['OVER REURING']
          }
        }
      }
    }
  }
</script>
