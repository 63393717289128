<template>
  <div
    class="gb-field gb-field__select"
    :class="{ error: isError, 'fit-width': isFitWidth, disabled: disabled }">
    <span class="gb-field__icon" v-if="!isValidValue"></span>
    <component
      :is="currentView"
       v-bind="$attrs"
       v-on="$listeners"
       :default-text="''"
       @change="change"
       :disabled="disabled"
       class="gb-field__area uppercased"
    />
    <label class="gb-field__label"> {{ label }}</label>
    <p class="gb-field__invalid"
       v-if="isError">{{ errorMessage || error.message }}</p>
  </div>
</template>

<script>
import _ from 'lodash'
import SelectDesktop from './SelectDesktop'
import SelectMobile from './SelectMobile'

export default {
  name: 'app-select',
  inheritAttrs: false,
  props: {
    label: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    errorMessage: {
      type: String
    },
    errorstatus: {
      type: Boolean,
      default: false
    },
    dataWidth: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showError: false,
      beginCatchError: false
    }
  },
  created() {
    this.beginCatchError = true
  },
  computed: {
    isFitWidth() {
      if (process.server) {
        return true
      }

      return (
        this.$screen.resize &&
        !this.$screen.smallScreen() &&
        this.dataWidth === 'fit'
      )
    },
    currentView() {
      if (process.server) {
        return SelectDesktop
      }
      return this.$screen.resize && this.$screen.smallScreen()
        ? SelectMobile
        : SelectDesktop
    },
    isValidValue() {
      return (
        !_.isUndefined(this.$attrs.value) &&
        !_.isNull(this.$attrs.value) &&
        this.$attrs.value !== ''
      )
    },
    isError() {
      return this.showError && (this.errorMessage || !!this.error)
    },
    error() {
      if (!this.beginCatchError) {
        return null
      }
      if (this.required && !this.isValidValue) {
        return {
          type: 'empty',
          message: 'This field is required.' // this.$t('This field is required.')
        }
      }
      return null
    }
  },
  methods: {
    change() {
      this.showError = true
    }
  },
  watch: {
    error(val) {
      let msg = val ? val.message : ''
      this.$emit('update:errorMessage', msg)
    }
  },
  components: {
    SelectDesktop,
    SelectMobile
  }
}
</script>
