<template>
  <div :style="{backgroundColor: selected ? '#D7EFED' : 'transparent'}">
    <p class="gb-field__select-holder">{{ text }}</p>
    <button
      v-if="selected"
      @click="change({ value: '' })"
      class="gb-field__select-close"
    >
      X
    </button>
    <select
      :name="name"
      :disabled="disabled"
      :value="selectedValue"
      @change="change($event.target.value)"
      data-none-selected-text=""
    >
      <option
        v-for='(item,index) in items'
        :value="item.key"
        :disabled="disabledItem(item)"
        :key="index"
        class="uppercased"
      >
        <span>{{ item.text }}</span>
      </option>
    </select>
  </div>
</template>
<script>
  import _ from 'lodash'
  export default {
    inheritAttrs: false,

    model: {
      prop: 'value',
      event: 'change'
    },
    props: {
      items: {
        type: Array,
        required: true
      },
      value: [String, Number],
      name: {
        type: String
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      selected() {
        return !_.isEmpty(this.selectedValue)
      },
      selectedValue() {
        let selectedItem = this.getSelectedItem()
        return selectedItem ? selectedItem.key : ''
      },
      text() {
        let selectedItem = this.getSelectedItem()
        if (selectedItem) {
          return selectedItem.displayText
            ? selectedItem.displayText
            : selectedItem.text
        }
        return ''
      }
    },
    updated() {
      this.$nextTick(() => {
        // $(this.$el)
        //   .find('select')
        //   .val(this.value)
      })
    },
    methods: {
      change(newVal) {
        let selectedItem = this.getSelectedItem(newVal)
        this.$emit('change', selectedItem.key, this.value)
      },
      disabledItem(option) {
        if (option.disabled) {
          return option.disabled
        }
        let separator = '---------------------------------'
        if (option.text === separator) {
          return 'disabled'
        }
      },
      getSelectedItem(val) {
        if (!this.items || this.items.length <= 0) {
          return ''
        }
        let value = val
        if (!val) {
          value = this.value ? this.value.toString() : ''
        }
        let selectedItem = _.find(this.items, item => {
          return item.key && item.key.toString() === value
        })
        selectedItem = selectedItem || this.items[0]
        return selectedItem
      }
    }
  }
</script>
