<template>
  <div class="header-wrap" :class="{'minimized': isMinimized, 'menu-open': isMenuOpen}" v-scroll="onScroll">
    <div class="header-background">
      <div class="header-leaves animate-transform t-o-top-left"></div>
      <header class="header animate-transform">
        <div class="flex-box festival-bar animate-transform">
          <nuxt-link tag="h1" to="/" class="logo t-o-top-left interactive">
            <a class="logo-inner" v-if="stayHomePlease">
              <span class="logo-brand"><img width="500" src="~assets/images/REURING_Thuis.png" alt="Reuring festival logo"></span>
            </a>
            <a class="logo-inner" v-else>
              <span class="logo-brand"><img src="~assets/images/Reuring_logo.svg" alt="Reuring festival logo"></span>
              <span class="logo-name">festival</span>
            </a>
          </nuxt-link>
          <div class="festival interactive">
            <span class="location animate-transform t-o-top-right">PURMEREND</span>
            <span class="time animate-transform t-o-top-right"><span class="time-date">28 - 30 JUNI 2024</span></span>
          </div>
        </div>
        <div class="flex-box menu-bar animate-transform">
          <nav class="nav animate-transform t-o-bottom-left interactive" :class="{'open': isMenuOpen}">
            <div class="menu-button-wrap">
              <div class="menu-button uppercased" @click.prevent.stop="toggleMenu">
                <div class="icon-bar">
                  <span class="line line-1"></span>
                  <span class="line line-2"></span>
                  <span class="line line-3"></span>
                </div>menu
              </div>
              <nuxt-link
                :to="{path: '/', hash: '#programma'}"
                class="anchor-item uppercased animate-transform">
                Programma
              </nuxt-link>
            </div>
            <div class="menu-wrapper" ref="menuWrapper">
              <ul class="menu" ref="menu" @click="toggleMenu(false)">
                <nuxt-link
                  tag="li"
                  v-for="item in navItems"
                  class="menu-item"
                  :to="item.routerTo"
                  :key="item.id">
                  <a class="uppercased">{{ item.title }}</a>
                </nuxt-link>
                <li class="download" v-if="false">
                  <a href="/files/Reuring_Krant_2019.pdf" target="_blank">
                    <div class="text uppercased">
                      <span>download</span>
                      <span>programma</span>
                    </div>
                    <span class="download-icon"><i class="icon-img icon-img-download"></i></span>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <div class="social-bar interactive">
            <a href="https://www.facebook.com/Reuring" target="_blank" class="social-item"><i class="fa fa-facebook"></i></a>
            <a href="https://www.instagram.com/reuringfestival/" target="_blank" class="social-item"><i class="fa fa-instagram"></i></a>
            <a href="https://open.spotify.com/user/reuringfestival?si=bWVhrY8MT_ywo3smGfR7Og" target="_blank" class="social-item"><i class="fa fa-spotify"></i></a>
            <a href="https://www.youtube.com/user/reuringfestival" target="_blank" class="social-item"><i class="icon icon-youtube"></i></a>
            <a href="https://www.flickr.com/photos/reuringfestival/albums" target="_blank" class="social-item"><i class="icon icon-flickr"></i></a>
          </div>
        </div>
      </header>
    </div>
  </div>
</template>

<script>
import { throttle, find } from 'lodash'
import { listInMenu as listInMenuContent } from '@/libs/apollo/queries/content-footer'

const defaultNavItems = [
  {
    title: 'NIEUWS',
    routerTo: { path: '/news' }
  },
  {
    title: 'BEREIKBAARHEID',
    routerTo: { path: '/bereikbaarheid' }
  }
]

export default {
  name: 'app-header',

  props: {
    minimizationThreshold: {
      type: Number,
      default: 35
    }
  },

  data() {
    return {
      isMinimized: false,
      isMenuOpen: false,
      navItems: [],
      stayHomePlease: false,
      documentLink: 'https://cloud.squidex.io/api/assets/3dd2fcf2-7efe-4915-a296-71b31edc2a56?q=133f7f8a-1368-4a7b-3fc4-75fa11753da3'
    }
  },

  apollo: {
    navItems: {
      query: listInMenuContent.query,
      fetchPolicy: 'cache-first',
      prefetch: true,
      update(data) {
        const programNavItems = [{ title: 'Programma', routerTo: {path: '/', hash: '#programma'} }]

        return [
          ...programNavItems,
          ...defaultNavItems,
          ...data.queryContentFooterContents.map(it => ({
            title: it.data.title.iv,
            routerTo: {
              name: 'general-id',
              params: { id: it.data.shortName.iv }
            }
          }))
        ]
      }
    }
  },

  methods: {
    isMobile () {
      if (process.server) {
        return false
      }
      return window.matchMedia('only screen and (max-width: 768px)').matches
    },

    toggleMenu(isShow) {
      const { TweenLite } = require('gsap/TweenMax')
      if (typeof isShow === 'boolean') {
        if (isShow === this.isMenuOpen) {
          return
        }
        this.isMenuOpen = isShow
      } else {
        this.isMenuOpen = !this.isMenuOpen
      }

      const isMobile = this.isMobile()

      const menuHeight = isMobile
        ? document.documentElement.clientHeight
        : this.$refs.menu.clientHeight

      const duration = isMobile ? 0.9 : 0.6

      TweenLite.to(this.$refs.menu, duration, {
        scaleY: this.isMenuOpen ? 1 : 0,
        opacity: this.isMenuOpen ? 1 : 0,
        ease: Expo.easeOut
      })

      TweenLite.to(this.$refs.menuWrapper, duration, {
        height: this.isMenuOpen ? menuHeight : 0,
        ease: Expo.easeOut
      })
    },

    onScroll: throttle(function (evt) {
      // debugger
      this.isMinimized = window.scrollY > this.minimizationThreshold
    }, 100)
  },

  watch: {
    '$route': function() {
      this.toggleMenu(false)
    },

    isMinimized(newVal) {
      this.$emit('minimized', newVal)
    },

    isMenuOpen (newVal) {
      this.$emit('menu-toggled', newVal)
    }
  }
}
</script>
