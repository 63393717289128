import Screen from './screen'
import Vue from 'vue'
import { throttle } from 'lodash'

export default ({ app }) => {
  Vue.screen = Screen
  Vue.prototype.$screen = Screen
  let defineReactive = Vue.util.defineReactive
  defineReactive(Vue.prototype.$screen, 'resize', 1)
  let throttleResize = throttle(() => {
    ++Vue.prototype.$screen.resize
  }, 500)

  if (process.client) {
    window.addEventListener('resize', throttleResize)
  }
}