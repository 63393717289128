<template>
  <div :class="{'ready': isLayoutReady, 'main-layout': true}">
    <app-header
      :minimization-threshold="headerMinimizedThreshold"
      ref="appHeader"
      @menu-toggled="onMenuToggled"
    />
    <div class="static-wrapper" :style="wrapperStyle">
      <div class="main-container">
        <nuxt/>
      </div>
      <app-footer />
    </div>
  </div>
</template>

<script>
  import AppFooter from '@/components/AppFooter'
  import AppHeader from '@/components/AppHeader'
  import { willPrefetch } from 'vue-apollo'

  const prefetch = component => willPrefetch(component, ({ route }) => route.name != null)

  // FIXME:
  const minimizedHeaderHeight = {
    desktop: 100,
    mobile: 40
  }

  export default {
    head () {
      return {
        meta: [
          { hid: 'og:url', property: 'og:url', content: `https://${process.env.CUSTOM_DOMAIN}${this.$route.fullPath}` }
        ]
      }
    },

    data () {
      return {
        url: null,
        defaultHeaderHeight: 0,
        isLayoutReady: false
      }
    },

    mounted () {
      this.$nextTick(() => {
        this.defaultHeaderHeight = this.$refs.appHeader.$el.clientHeight
        this.$nextTick(() => {
          this.isLayoutReady = true
        })
        window.addEventListener('resize', () => {
          this.defaultHeaderHeight = this.$refs.appHeader.$el.clientHeight
        })
      })
    },

    computed: {
      wrapperStyle () {
        return {
          marginTop: `${this.defaultHeaderHeight}px`
        }
      },

      isMobile () {
        if (process.server) {
          return false
        }
        return window.matchMedia('only screen and (max-width: 768px)').matches
      },

      headerMinimizedThreshold () {
        return this.isMobile ? minimizedHeaderHeight.mobile : minimizedHeaderHeight.desktop
      }
    },

    methods: {
      onMenuToggled (isOpened) {
        this.$nextTick(() => {
          document.querySelector('body').classList.toggle('menu-opened', isOpened)
        })
      }
    },

    components: {
      AppHeader: prefetch(AppHeader),
      AppFooter: prefetch(AppFooter)
    }
  }
</script>

<style lang="scss" scoped>
  .main-layout {
    &::before {
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: white;
      transition: opacity 0.25s, visibility 0.25s;
      z-index: 100;
    }
    &.ready {
      &::before {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
</style>
