<template>
  <div class="gb-field gb-field__number"
       :class="{active: isActive, focus: inputing, error: isError}">
    <label class="gb-field__label">{{ label }}</label>
    <input class="gb-field__area"
           :class="{disabled: isDisabled}"
           type="text"
           autocomplete="off"
           :disabled="isDisabled"
           :value="value"
           @input="input"
           @focus="focus"
           @blur="blur">
    <span class="gb-character-counter"
          v-if="isShowCharacterCounter">{{ characterCounterInfo }}</span>
    <p class="gb-field__invalid"
       v-if="isError">{{ errorMessage || error.message }}</p>
  </div>
</template>

<script>
import InputBase from './input'

export default {
  name: 'app-mobile-input',
  mixins: [InputBase],
  computed: {
    isMobileNumber() {
      return /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/.test(
        this.value
      )
    },
    isActive() {
      return this.active || this.isValidValue
    },
    error() {
      if (this.basicError) {
        return this.basicError
      }

      if (!this.isMobileNumber) {
        return {
          type: 'invalidMobileNumber',
          message: 'Vul een geldig mobiel nummer in'
        }
      }

      return null
    }
  }
}
</script>
