<template>
  <div class="container ignore notfound-page">
    <div class="container-inner">
      <h2 class="content-title">{{ title }}</h2>
      <div class="block-content">
        <div class="content-item">
          {{ content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: 'static',

  props: ['error'],

  computed: {
    notFound () {
      return this.error.statusCode === 404
    },

    title () {
      if (this.notFound) {
        return 'Pagina niet gevonden'
      } else {
        return 'Er is iets misgegaan'
      }
    },

    content () {
      if (this.notFound) {
        return 'Helaas, deze pagina bestaat niet'
      } else {
        return 'Probeer het later nog eens'
      }
    }
  }
}
</script>

<style lang="scss">
  .notfound-page {
    padding-top: 1.5rem;
    min-height: calc(100vh - 4rem);
  }
</style>
