import Vue from 'vue'

Vue.directive('scroll', {
  inserted: function(el, binding) {
    let f = function(evt) {
      binding.value(evt, el)
    }
    window.addEventListener('scroll', f)
    el.$destroy = () => el.removeEventListener('scroll', f)
  },

  unbind: function (el) {
    el.$destroy()
  }
})