import Vue from 'vue'
import AppSelect from '@/components/global/app-select/AppSelect'
import TextInput from '@/components/global/app-input/TextInput'
import MobileInput from '@/components/global/app-input/MobileInput'
import EmailInput from '@/components/global/app-input/EmailInput'

Vue.component('app-select', AppSelect)
Vue.component('app-text-input', TextInput)
Vue.component('app-mobile-input', MobileInput)
Vue.component('app-email-input', EmailInput)
